import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { authStoreName } from 'provider-authenticate/lib/authenticate.service';
import { AuthenticationGuard } from './routes/guards/authenticated/authentication.guard';

export enum RouteNames {
	LOGIN = 'login',
	PASSWORD_FORGOT = 'wachtwoord-vergeten',
	PASSWORD_SET = 'wachtwoord-instellen',
	INTAKE = 'intake',
	REGISTER = 'registreren',
	ACCOUNT_VERIFY = 'account/activeer',
	QUESTIONAIRE = 'vragenlijst',
	TASKS = 'takenlijst',
	ACCOUNT = 'account',
	DECEASED = 'gegevens-overledene',
	PAYMENT_FEEDBACK = 'betaling/:deceasedId',
	PAYMENT_FEEDBACK_WITH_ID = 'betaling/:deceasedId/:paymentId',
}

/* istanbul ignore next */
export const appRoutes: Route[] = [
	{
		path: RouteNames.LOGIN,
		loadChildren: () =>
			import('./pages/login/login.module').then((m) => m.LoginPageModule),
		data: {
			hideMenu: true,
			hideHeader: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.PASSWORD_FORGOT,
		loadChildren: () =>
			import('./pages/password-forgot/password-forgot.module').then(
				(m) => m.PasswordForgotPageModule,
			),
		data: {
			hideMenu: true,
			hideHeader: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.PASSWORD_SET,
		loadChildren: () =>
			import('./pages/password-set/password-set.module').then(
				(m) => m.PasswordSetPageModule,
			),
		data: {
			hideMenu: true,
			hideHeader: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.INTAKE,
		loadChildren: () =>
			import('./pages/intake/intake.module').then(
				(m) => m.IntakePageModule,
			),
		data: {
			hideMenu: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.REGISTER,
		loadChildren: () =>
			import('./pages/register/register.module').then(
				(m) => m.RegisterPageModule,
			),
		data: {
			hideMenu: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.ACCOUNT_VERIFY,
		loadChildren: () =>
			import('./pages/account-verify/account-verify.module').then(
				(m) => m.AccountVerifyPageModule,
			),
		data: {
			hideMenu: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.QUESTIONAIRE,
		loadChildren: () =>
			import('./pages/dashboard/questionaire/questionaire.module').then(
				(m) => m.QuestionairePageModule,
			),
		data: {
			hideMenu: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isAuthenticated()],
	},
	{
		path: RouteNames.TASKS,
		loadChildren: () =>
			import('./pages/dashboard/tasks/tasks.module').then(
				(m) => m.TasksPageModule,
			),
		canActivate: [() => inject(AuthenticationGuard).isAuthenticated()],
	},
	{
		path: RouteNames.ACCOUNT,
		loadChildren: () =>
			import('./pages/dashboard/account/account.module').then(
				(m) => m.AccountPageModule,
			),
		canActivate: [() => inject(AuthenticationGuard).isAuthenticated()],
	},
	{
		path: RouteNames.DECEASED,
		loadChildren: () =>
			import('./pages/dashboard/deceased/deceased.module').then(
				(m) => m.DeceasedPageModule,
			),
		canActivate: [() => inject(AuthenticationGuard).isAuthenticated()],
	},
	{
		path: RouteNames.PAYMENT_FEEDBACK,
		loadChildren: () =>
			import(
				'./pages/dashboard/payment-feedback/payment-feedback.module'
			).then((m) => m.PaymentFeedbackPageModule),
		data: {
			hideMenu: !sessionStorage.getItem(authStoreName),
		},
	},
	{
		path: RouteNames.PAYMENT_FEEDBACK_WITH_ID,
		loadChildren: () =>
			import(
				'./pages/dashboard/payment-feedback/payment-feedback.module'
			).then((m) => m.PaymentFeedbackPageModule),
		data: {
			hideMenu: !sessionStorage.getItem(authStoreName),
		},
	},
	{
		path: '**',
		redirectTo: `/${RouteNames.LOGIN}`,
	},
];
