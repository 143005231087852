import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LoadingStates } from '@nx/core/lib/interfaces/loading-states.interface';
import { AuthenticateService } from 'provider-authenticate/lib/authenticate.service';
import { RouteNames } from '../../../app.routes';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationGuard {
	constructor(
		private readonly router: Router,
		private readonly authService: AuthenticateService,
	) {}

	/**
	 * Returns whether access is allowed for route.
	 * Redirect to login when session is not set
	 */
	isAuthenticated(): boolean | UrlTree {
		if (
			this.authService.authenticateStateComputed().state ===
			LoadingStates.LOADED
		) {
			return true;
		}
		return this.router.parseUrl(`/${RouteNames.LOGIN}`);
	}

	/**
	 * Returns whether access is allowed for route.
	 * Redirect to dashboard when session is set
	 */
	isUnauthenticated(): boolean | UrlTree {
		if (
			this.authService.authenticateStateComputed().state !==
			LoadingStates.LOADED
		) {
			return true;
		}
		return this.router.parseUrl(`/${RouteNames.TASKS}`);
	}
}
