import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, computed, signal } from '@angular/core';
import { ApiErrorHelper } from '@nx/core/lib/helpers/api-error/api-error.helper';
import {
	Authenticate,
	AuthenticateErrorCodes,
} from '@nx/core/lib/interfaces/authenticate.interface';
import {
	LoaderState,
	LoadingStates,
} from '@nx/core/lib/interfaces/loading-states.interface';
import {
	AuthenticationRequestAPI,
	AuthenticationResponseAPI,
} from 'api-interfaces';
import { Observable, catchError, map, of } from 'rxjs';

export const authStoreName = 'auth';

@Injectable({
	providedIn: 'root',
})
export class AuthenticateService {
	private _authenticateStateSig = signal<LoaderState<Authenticate, string>>({
		state: LoadingStates.IDLE,
	});
	authenticateStateComputed = computed(() => this._authenticateStateSig());

	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {
		this.readSessionFromStorage();
	}

	private readSessionFromStorage() {
		const sessionData = sessionStorage.getItem(authStoreName);
		if (sessionData) {
			this._authenticateStateSig.set({
				state: LoadingStates.LOADED,
				data: JSON.parse(sessionData),
				error: undefined,
			});
		}
	}

	authenticate(body: AuthenticationRequestAPI): Observable<Authenticate> {
		this._authenticateStateSig.set({
			state: LoadingStates.LOADING,
		});
		return this.http
			.post<AuthenticationResponseAPI>(
				`${this.endpoint}v1/authenticate`,
				body,
			)
			.pipe(
				map((response) => {
					const result = this.parseAuthenticate(response);
					this._authenticateStateSig.set({
						state: LoadingStates.LOADED,
						data: result,
						error: undefined,
					});
					sessionStorage.setItem(
						authStoreName,
						JSON.stringify(result),
					);
					return result;
				}),
				catchError((errorResponse) => {
					const error =
						ApiErrorHelper.getError<AuthenticateErrorCodes>(
							errorResponse,
							AuthenticateErrorCodes,
						);

					this._authenticateStateSig.set({
						state: LoadingStates.ERROR,
						error: error,
					});
					sessionStorage.removeItem(authStoreName);
					return of({
						token: undefined,
						expiresAt: undefined,
					} as Authenticate);
				}),
			);
	}

	logout(reason?: string) {
		this._authenticateStateSig.set({
			state: LoadingStates.IDLE,
			error: reason,
		});
		sessionStorage.removeItem(authStoreName);
	}

	private parseAuthenticate(
		response: AuthenticationResponseAPI,
	): Authenticate {
		return {
			token: response.data?.token,
			expiresAt: response.data?.expiresAt,
		};
	}
}
