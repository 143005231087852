export enum AuthenticateErrorCodes {
	NOT_VERIFIED = 'ERROR_ACCOUNT_10007',
	UNKNOWN = 'ERROR_UNKNOWN',
	UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface Authenticate {
	token?: string;
	expiresAt?: string;
}
